export const apiId = {
  aboutPage: 'about-page',
  basicPage: 'basic-pages',
  credentials: 'whitelabel-page',
  experiencePage: 'experience-pages',
  faqList: 'faq-lists',
  blogPostList: 'blog-post-lists',
  helpCenterForm: 'help-center-forms',
  testimonialsList: 'testimonials-lists',
};
